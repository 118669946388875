'use strict';
/**
 * type             Default: text.
 * required         Default: true. Whether or not the field must contain text
 * placeholder      placeholder string shown when there's no text in the field
 * value            value of the input.
 * validationRegex  regex that validates the input
 * filterRegex      regex used to filter unsupported characters from the value
 * allowEmptyString Default: false. If true the value can be ""
 */

window.GUI = function (GUI) {
    class InputPopup extends GUI.GeneralPopup {
        //region Static
        static Template = function () {
            /**
             *
             * @param type
             * @param placeholder
             * @param pattern           don't pass in a REGEX object, patternified regexes as string only!
             * @param required
             * @param value
             * @return {jQuery|HTMLElement}
             */
            var getInput = function getInput(type, placeholder, pattern, required, value) {
                var requiredTx = required ? "required " : '',
                    patternTx = pattern ? 'pattern="' + pattern + '" ' : '';
                return $('<input form="form" ' + 'type="' + type + '" ' + 'placeholder="' + placeholder + '" ' + patternTx + requiredTx + 'value="' + value + '">');
            };

            return {
                getInput: getInput
            };
        }(); //endregion Static

        constructor(type, content) {
            super(...arguments);
            this.element.addClass("input-popup");
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                let inp = this.content.input,
                    type = inp.type ? inp.type : 'text',
                    rqrd = inp.hasOwnProperty('required') ? inp.required : true,
                    ptrn = inp.validationRegex ? inp.validationRegex.patternify() : '',
                    plhd = inp.placeholder ? inp.placeholder : '',
                    val = inp.value ? inp.value : ''; // if a filter regex has been provided, ensure the value given does not contain any unsupported characters.

                if (inp.filterRegex && val !== '') {
                    val = val.regexFilter(inp.filterRegex);
                } // needed for User-ID


                if (this.content.allowEmptyString) {
                    rqrd = false;
                }

                this.elements.buttonContainer.prepend(InputPopup.Template.getInput(type, plhd, ptrn, rqrd, val));
                this.input = this.element.find("input");

                if (val !== '') {
                    this.content.input.result = val;
                }

                return Q.resolve();
            });
        }

        viewDidAppear() {
            let baseCall = super.viewDidAppear(...arguments);

            this.elements.form.toggleClass('invalid', !this._checkValidity()); // add autofocus to the first element

            this._boundOnInput = this._onInput.bind(this);
            this.input.on('input', this._boundOnInput); // WARNING: keep in mind when we support more than one input field!! (pressing enter in first field...)

            this.input.keyup(this._keyUp.bind(this));
            this.element.find("input:first").focus();

            this._moveCursorToEnd();

            return baseCall;
        }

        viewWillDisappear(viewRemainsVisible) {
            this.input.blur(); // fix for iOS 10, otherwise the keyboard will appear after an animation

            super.viewWillDisappear(viewRemainsVisible);
        }

        viewDidDisappear() {
            this.input.off('input', this._boundOnInput);
            this.input.keyup(null);
            super.viewDidDisappear(...arguments);
        }

        buttonTapped(type, index) {
            if (index === 0) {
                this.defer.resolve(this.content.input);
            } else {
                super.buttonTapped(type, index);
            }
        }

        _onInput() {
            var value = this.input[0].value,
                valid = this._checkValidity();

            this.elements.form.toggleClass('invalid', !valid);
            this.content.input.result = valid ? value : "";
        }

        _keyUp(ev) {
            if (ev.keyCode === 13) {
                this._checkValidity() && this.defer.resolve(this.content.input);
            }
        }

        /**
         * Checks if the inputField has a value and if it's valid based on the pattern.
         * @returns {boolean}
         * @private
         */
        _checkValidity() {
            var value = this.input[0].value,
                valid,
                patternValid;

            if (this.content.allowEmptyString) {
                valid = value != null;
            } else {
                valid = value !== "" && value != null;
            }

            patternValid = this.input[0].checkValidity();
            return valid && patternValid;
        }

        /**
         * This moves the cursor to the end of the input element
         */
        _moveCursorToEnd() {
            if (this.input[0].setSelectionRange) {
                // Double the length because Opera is inconsistent about whether a carriage return is one character or two
                var len = this.input.val().length * 2;
                this.input[0].setSelectionRange(len, len);
            } else {
                this.input.val(this.input.val());
            }
        }

    }

    GUI.InputPopup = InputPopup;
    return GUI;
}(window.GUI || {});
