'use strict'; // TODO this should be a PopupExt in the NavigationComp

/**
 * Popup content to display:
 *
 * [title]: String
 * message: String
 * [buttonOk]: String|Boolean - has always popup color
 * [buttonCancel]: String|Boolean - gray cancel button
 * buttons: [{title: String, color: {Color}}] - title and color, otherwise popup color
 * icon: {Icon}
 * color: {Color}
 */

(function () {
    GUI.PopupManager = class PopupManager {
        static TransitionOptions = {
            duration: 200,
            display: null
        }
        
        constructor() {
            this.name = "PopupManager";
            this.popupView = $('<popup-view></popup-view>');
            this.currentPopupPromise = null;
            this.popupQueue = [];
        }

        /**
         * Adds the popup to the popups array and displays it on top
         * @param content
         * @param type
         * @param force
         * @returns {Promise.promise|*}
         */
        showPopup(content, type, force) {
            Debug.GUI.Popups && console.log(this.name, "showPopup: " + JSON.stringify(content));

            if (force && this.currentPopupPromise) {
                if (this.currentPopupPromise.hasOwnProperty("popup")) {
                    // it's currently visible
                    // remove the old popup and add to queue
                    var args = arguments;
                    return this._animatePopupOut(this.currentPopupPromise.popup).then(function () {
                        if (this.currentPopupPromise.popup) {
                            this._putBackIntoPopupQueue(this.currentPopupPromise);
                        } else {
                            // no .popup attribute means that this popup has already been removed, hence shouldn't be
                            // put back into the queue!"
                        }
                        this.currentPopupPromise = null;
                        return this.showPopup.apply(this, args);
                    }.bind(this));
                } else {
                    // is about to be dismissed, just add to queue, it will be processed after the prev. popup is dismissed...
                    return this._addToPopupQueue(arguments, true);
                }
            } // check if popup container should be added


            if (!this.currentPopupPromise && !this.popupView.parent().length > 0) {
                $(document.body).append(this.popupView);
                this.popupView.velocity("transition.fadeIn", PopupManager.TransitionOptions); // this prevents the scrolling on the background (was possible on iOS devices) (Wrike: 71600575)

                this.hammerHandler = Hammer(this.popupView[0]).on(tapEvent(), function (e) {
                    // ignore
                    e.stopPropagation();
                    Debug.GUI.Popups && console.info("ignore click on popup");
                });
            } else if (this.currentPopupPromise) {
                return this._addToPopupQueue(arguments);
            } // if no content is given, create empty for cancel button


            if (!content) {
                content = {
                    buttonCancel: true
                };
            } // if no type is given -> take general!


            if (!type) {
                type = PopupType.GENERAL;
            } // prepare color


            if (!content.color) {
                content.color = window.Styles.colors.green;
            } // buttons


            var buttons = [];

            if (content.buttons instanceof Array) {
                buttons = buttons.concat(content.buttons);
            }

            if (content.buttonOk) {
                var okayBtn = {
                    title: typeof content.buttonOk === "string" ? content.buttonOk : _("okay"),
                    type: GUI.PopupBase.ButtonType.OK
                };
                buttons.push(okayBtn);
                delete content.buttonOk;
            }

            if (content.buttonCancel) {
                var cancelBtn = {
                    title: typeof content.buttonCancel === "string" ? content.buttonCancel : _("cancel"),
                    type: GUI.PopupBase.ButtonType.CANCEL
                };
                buttons.push(cancelBtn);
                delete content.buttonCancel;
            } // color for buttons


            for (var i = 0; i < buttons.length; i++) {
                if (!buttons[i].color) {
                    var color;

                    if (buttons[i].type === GUI.PopupBase.ButtonType.CANCEL) {
                        color = Color.STATE_INACTIVE;
                    } else {
                        color = content.color; // for ButtonType.OK and all ButtonType.CUSTOM use the popup color
                    }

                    buttons[i].color = color;
                }
            }

            content.buttons = buttons; // create the popup and show it

            var popup = new GUI[type](type, content);

            // respect ViewLifeCycle
            popup.viewDidLoad().then(() => {
                // animate in after viewDidLoad resolved
                // prevent double authentication
                this._animatePopupIn(popup); // promise
            });

            var promise = popup.getPromise(content);
            promise.popup = popup; // also return the popup instance to give the caller the possibility to update the popup..
            // Add this properties manually because _addToPopupQueue() is not used if the queue is empty,
            // but we need this properties if we cancel a popup after forcing a popup to be shown.

            promise.def = popup.defer;
            promise.args = arguments; // Save as the current promise

            this.currentPopupPromise = promise; // Can't use finally, this will mess up our queue!

            promise.then(function () {
                this.removePopup(promise);
            }.bind(this), function () {
                this.removePopup(promise);
            }.bind(this));
            return promise;
        }

        /**
         * dismisses the given (or the top) popup
         * @param popupPromise
         */
        removePopup(popupPromise) {

            if (!popupPromise.popup) {
                // check if it's probably in the queue
                this._removeFromPopupQueue(popupPromise);

                return; // if we remove the popup, we may reject the promise -> removePopup will be called twice! -> we remove the popup prop of the promise to bail out the 2nd time
            }

            var popup = popupPromise.popup;
            delete popupPromise.popup;

            if (popupPromise.isPending()) {
                popup.defer.reject();
            }

            this._animatePopupOut(popup).then(function () {
                popup.destroy();
                this.currentPopupPromise = null;

                if (this.popupQueue.length > 0) {
                    var nextOne = this._getNextFromPopupQueue();

                    if (nextOne.hasOwnProperty("popup")) {
                        // the popup was already shown, just show again and that's it
                        this.currentPopupPromise = nextOne;

                        this._animatePopupIn(this.currentPopupPromise.popup);
                    } else {
                        // the popup wasn't actually shown until now..
                        var res = this.showPopup.apply(this, nextOne.args);
                        nextOne.popup = res.popup;
                        nextOne.def.resolve(res);
                        delete nextOne.def;
                        delete nextOne.args;
                    }
                } else {
                    // remove
                    this.popupView.velocity("reverse", function () {
                        if (!this.currentPopupPromise) {
                            // check again! fast popups will be dismissed otherwise
                            this.popupView.remove();
                            this.hammerHandler.dispose();
                        } else {
                            // Show the popup again, we still have a currentPopupPromise, so we can't just hide the popupView
                            this.popupView.velocity("reverse");
                        }
                    }.bind(this));
                }
            }.bind(this));
        }

        _animatePopupIn(popup) {
            var def = Q.defer();
            this.popupView.append(popup.getElement());
            popup.viewWillAppear();
            popup.getElement().velocity("transition.expandIn", {
                duration: PopupManager.TransitionOptions.duration,
                display: PopupManager.TransitionOptions.display,
                complete: function complete() {
                    popup.viewDidAppear();
                    def.resolve();
                }
            });
            return def.promise;
        }

        _animatePopupOut(popup) {
            var def = Q.defer();
            popup.processWhenVisible(function () {
                popup.viewWillDisappear();
                popup.getElement().velocity("reverse", function () {
                    popup.getElement().remove();
                    popup.viewDidDisappear();
                    def.resolve();
                }.bind(this));
            }.bind(this));
            return def.promise;
        }

        _addToPopupQueue(args, unshift) {
            // put into queue
            var def = Q.defer();
            def.promise.def = def;
            def.promise.args = args;

            if (unshift) {
                this.popupQueue.unshift(def.promise);
            } else {
                this.popupQueue.push(def.promise);
            }

            return def.promise;
        }

        _getNextFromPopupQueue() {
            return this.popupQueue.shift();
        }

        _putBackIntoPopupQueue(popupPromise) {
           this.popupQueue.unshift(popupPromise);
        }

        _removeFromPopupQueue(popupPromise) {
            var idx = this.popupQueue.indexOf(popupPromise);

            if (idx !== -1) {
                this.popupQueue.splice(idx, 1); // .def is not available if the popup has not been shown yet
                popupPromise.def && popupPromise.def.reject();
             }
        }
    }
})();
