'use strict';
/**
 * type             Default: text.
 * required         Default: true. Whether or not the field must contain text
 * userValue        value of the input.
 */
window.GUI = function (GUI) {
    class LoginPopup extends GUI.GeneralPopup {

        constructor(type, content) {
            super(...arguments);
            this.element.addClass("login-popup");
            this._username = "";
            this._password = "";
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.prependReactComp({
                    reactComp: GUI.LxReactLoginForm,
                    compProps: {
                        user: this._username,
                        hideSubmit: true,
                        onSubmit: this._handleRxSubmit.bind(this),
                        onInputChanged: this._handleRxInputChanged.bind(this)
                    },
                    target: this.elements.buttonContainer
                });
                return Q.resolve();
            });
        }

        buttonTapped(type, index) {
            if (index === 0) {
                this._resolveWithCreds();
            } else {
                super.buttonTapped(type, index);
            }
        }

        _handleRxSubmit(user, pass) {
            this._username = user;
            this._password = pass;
            this._resolveWithCreds();
        }

        _handleRxInputChanged(user, pass, valid = false) {
            this._username = user;
            this._password = pass;

            const isValid = valid && nullEmptyString(this._username) && nullEmptyString(this._password);
            this.elements.form.toggleClass('invalid', !valid);
        }

        _resolveWithCreds() {
            if (nullEmptyString(this._username) && nullEmptyString(this._password)) {
                this.defer.resolve({
                    user: this._username,
                    pass: this._password
                });
            }
        }

    }

    GUI.LoginPopup = LoginPopup;
    return GUI;
}(window.GUI || {});
