'use strict';

class PositionInstructionNotification extends GUI.InstructionNotification {
    constructor(type) {
        super(type); // if NFC, prevent case if user touches a Tag while the interaction is in progress!

        if (this.type === Interaction.NFC.WRITE_POSITION) {
            nfcService.preventListening(true);
        } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
            this.quickActions = QuickActionUtility.getItems();
        }

        var platform = PlatformComponent.getPlatformInfoObj().platform;
        this.isAndroid = platform === PlatformType.Android;
        this._isLoxoneControl = LoxoneControl.hasLoxoneControl();
    }

    viewDidLoad() {
        return super.viewDidLoad(...arguments).then(() => {
            var iconSrc, iconClass;

            if (this.type === Interaction.NFC.WRITE_POSITION) {
                iconSrc = "resources/Images/ActiveMiniserver/NFC/icon-nfc-logo.svg";
                iconClass = "nfc-icon";
            } else if (this.type === Interaction.QR.WRITE_POSITION) {
                iconSrc = "resources/Images/ActiveMiniserver/QRCode/icon-qr-logo.svg";
                iconClass = "qr-icon";
            } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
                iconSrc = "resources/Images/ActiveMiniserver/QuickActions/icon-quick-actions-logo.svg";
                iconClass = "quick-action-icon";
            }

            this.elements.buttonOk = this._getButton(iconSrc, "button__icon " + iconClass);

            this._addButton(this.elements.buttonOk);
        });
    }

    viewWillAppear() {
        return super.viewWillAppear(...arguments).then(() => {
            this.setText(_('app-position.navigate'));
        });
    }

    viewDidAppear() {
        return super.viewDidAppear(...arguments).then(() => {
            this._boundOkayClick = this._onPositionOkClick.bind(this);
            this.elements.buttonOk.on("click", this._boundOkayClick); // TODO-thallth implement a "service" where we can register for this event (NavigationComp!) hashchange doesn't work properly everywhere!
            // listen to hashchange event to be able to approve the position

            this._boundHashChangeFn = this._hashChange.bind(this);
            window.addEventListener("hashchange", this._boundHashChangeFn); // also start interval as fallback for hashchange

            this._interval = setInterval(function () {
                this._approvePosition();
            }.bind(this), 500);
        });
    }

    viewWillDisappear() {
        clearInterval(this._interval);
        return super.viewWillDisappear(...arguments);
    }

    viewDidDisappear() {
        this.elements.buttonOk.off("click", this._boundOkayClick);
        window.removeEventListener("hashchange", this._boundHashChangeFn);
        return super.viewDidDisappear(...arguments);
    }

    destroy() {
        if (this.type === Interaction.NFC.WRITE_POSITION) {
            nfcService.preventListening(false);
        }

        return super.destroy();
    }

    _onPositionOkClick() {
        if (this.urlScheme) {
            NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Position);

            if (this.type === Interaction.NFC.WRITE_POSITION) {
                NavigationComp.showState(ScreenState.NfcTagInteractionScreen, {
                    type: Interaction.NFC.WRITE_POSITION,
                    urlScheme: this.urlScheme
                });
            } else if (this.type === Interaction.QR.WRITE_POSITION) {
                NavigationComp.showState(ScreenState.QRCodeResult, {
                    type: Interaction.QR.WRITE_POSITION,
                    text: this.urlScheme
                });
            } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
                var params = getURIParameters(this.urlScheme);
                var locInfo = UrlHelper.getLocationInfoFromLocationString(params.loc);
                var title = params.loc;
                var message;

                if (locInfo.control) {
                    var control = ActiveMSComponent.getControlByUUID(locInfo.control);
                    title = control.name;
                } else if (locInfo.room) {
                    var room = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.room, GroupTypes.ROOM);
                    title = room.name;
                } else if (locInfo.category) {
                    var cat = ActiveMSComponent.getStructureManager().getGroupByUUID(locInfo.category, GroupTypes.CATEGORY);
                    title = cat.name;
                }

                QuickActionUtility.addPosition(title, this.urlScheme).done(function () {
                    NavigationComp.showState(ScreenState.QuickActions);

                    if (this.isAndroid) {
                        message = _("shortcuts.android.save-success");
                    } else if (this._isLoxoneControl) {
                        message = _('loxone-control.quick-action.save.success');
                    } else {
                        message = _("quick-action.save.success");
                    }

                    var content = {
                        message: message,
                        buttonOk: true,
                        icon: Icon.SUCCESS
                    };
                    NavigationComp.showPopup(content);
                }, function () {
                    if (this.isAndroid) {
                        message = _("shortcuts.android.save-fail");
                    } else if (this._isLoxoneControl) {
                        message = _('loxone-control.quick-action.save.fail');
                    } else {
                        message = _("quick-action.save.fail");
                    }

                    var content = {
                        message: this._isLoxoneControl ? _('loxone-control.quick-action.save.fail') : _("quick-action.save.fail"),
                        buttonOk: true,
                        icon: Icon.ERROR,
                        color: window.Styles.colors.red
                    };
                    NavigationComp.showPopup(content);
                }.bind(this));
            }
        }
    }

    _onCancel() {
        if (this.type === Interaction.QR.WRITE_POSITION) {
            NavigationComp.showState(ScreenState.QRCode);
        } else if (this.type === Interaction.NFC.WRITE_POSITION) {
            NavigationComp.showState(ScreenState.Nfc);
        } else if (this.type === Interaction.QuickAction.WRITE_POSITION) {
            NavigationComp.showState(ScreenState.QuickActions);
        }

        super._onCancel();
    }

    _hashChange() {
        //console.log("#hashchange event");
        this._approvePosition();
    }

    _approvePosition() {
        // approve if the position is supported
        try {
            this.urlScheme = UrlHelper.createUrlStartFromCurrentLocation(); //console.info("current position valid!");
        } catch (e) {
            delete this.urlScheme; //console.info("current position not valid!");
        }

        var isValid = !!this.urlScheme;

        if (this.type === Interaction.QuickAction.WRITE_POSITION) {
            if (isValid && this.quickActions) {
                for (var actionIndex in this.quickActions) {
                    if (this.quickActions.hasOwnProperty(actionIndex) && !this.quickActions[actionIndex].hasOwnProperty("cmds")) {
                        var action = this.quickActions[actionIndex];
                        isValid = action.url !== this.urlScheme;

                        if (!isValid) {
                            // We need to exit the loop if the validation state has changed...
                            break;
                        }
                    }
                }
            }

            if (this.isValid) {
                if (isValid) {
                    this.setText(_('app-position.navigate'));
                    this.element.removeClass("instruction-notification--invalid");
                    this.elements.buttonOk.removeClass("disabled");
                }
            } else {
                if (!isValid) {
                    this.setText(_('app-position.not-possible'));
                    this.element.addClass("instruction-notification--invalid");
                    this.elements.buttonOk.addClass("disabled");
                }
            }

            this.isValid = isValid;
        } else {
            // always wait till the 2nd time!
            if (this.isValid) {
                if (isValid) {
                    this.setText(_('app-position.navigate'));
                    this.element.removeClass("instruction-notification--invalid");
                    this.elements.buttonOk.removeClass("disabled");
                }
            } else {
                if (!isValid) {
                    this.setText(_('app-position.not-possible'));
                    this.element.addClass("instruction-notification--invalid");
                    this.elements.buttonOk.addClass("disabled");
                }
            }

            this.isValid = isValid;
        }
    }

}

GUI.PositionInstructionNotification = PositionInstructionNotification;
