'use strict';

(function () {
    var ANIMATION_DURATION = 300; // TODO-thallth check fast showing/removing of notifications!

    function NotificationManager() {
        this.notificationPlaceholder = HD_APP ? $('<notifications-hd />') : $('<notifications />');
        this.notifications = [];
    }

    NotificationManager.prototype.toggleNotifications = function toggleNotifications(show) {
        this.notificationPlaceholder.css("display", show ? "" : "none");
    };

    NotificationManager.prototype.toggleNotification = function toggleNotification(notification, hidden) {
        if (hidden) {
            this.removeNotification(notification, false, true);
        } else {
            this.showNotification(notification, notification.type, true);
        }
    };

    NotificationManager.prototype.showNotification = function showNotification(notification, type, isReusedNotification) {
        if (typeof type === "undefined") type = NotificationType.INFO;

        if (type !== NotificationType.ERROR && type !== NotificationType.SILENT) {
            type = NotificationType.INFO; // TODO remove types and change everywhere!
        }

        notification.type = type;

        var idx = this._addNotificationToArray(notification);

        if (this.notifications.length === 1) {
            // add placeholder if the 1st notification is shown
            $(document.body).append(this.notificationPlaceholder);
        }

        if (!isReusedNotification) {
            notification.viewDidLoad();
        }

        notification.getElement().addClass("notification-type-" + type);
        notification.getElement().css({
            "-webkit-transform": "translateY(130%)",
            "-ms-transform": "translateY(130%)",
            // for iOS, otherwise it starts on top and flickers!
            "transform": "translateY(130%)"
        }); // check where to add to notification in the DOM

        if (this.notifications.length > 1 && this.notifications[idx + 1]) {
            notification.getElement().insertBefore(this.notifications[idx + 1].getElement());
        } else {
            this.notificationPlaceholder.append(notification.getElement());
        }

        notification.viewWillAppear();
        notification.getElement().velocity({
            translateZ: 0,
            // Force HA by animating a 3D property
            translateY: ["0%", "130%"] // 130% to start behind tab-bar

        }, {
            duration: ANIMATION_DURATION,
            complete: function () {
                notification.viewDidAppear();
            }
        });

        switch (notification.type) {
            case NotificationType.SUCCESS:
            case NotificationType.INFO:
                HapticFeedback(HapticFeedback.STYLE.SUCCESS);
                break;

            case NotificationType.ERROR:
                HapticFeedback(HapticFeedback.STYLE.ERROR);
                break;

            case NotificationType.WARNING:
                HapticFeedback(HapticFeedback.STYLE.WARNING);
                break;

            case NotificationType.SILENT:
                // nothing to do here, we don't want any HapticFeedback
                console.warn("NotificationManager: no haptic feedback trigger --> NotificationType: " + notification.type);
                break;
        } // style the recent notifications


        this._styleRecentNotifications();
    };

    NotificationManager.prototype.removeNotification = function removeNotification(notification, animated, doNotDestroy) {
        var idx = this.notifications.indexOf(notification);

        if (idx !== -1) {
            // check if the notification is visible, otherwise wait until it's visible and remove afterwards to avoid crashes
            if (!notification.isVisible()) {
                notification.processWhenVisible(this.removeNotification.bind(this, notification, animated, doNotDestroy));
                return;
            }

            try {
                notification.getElement().removeClass("notification-hidden"); // is needed due to doNotDestroy param
            } catch (e) {
                console.log(e.stack);
            }

            var onComplete = function onComplete() {
                try {
                    notification.getElement().remove();
                    notification.viewDidDisappear();
                    !doNotDestroy && notification.destroy();
                } catch (e) {
                    console.log(e.stack);
                }

                if (this.notifications.length === 0) {
                    this.notificationPlaceholder.remove();
                }
            }.bind(this);

            try {
                notification.viewWillDisappear();
            } catch (e) {
                console.log(e.stack);
            }

            this.notifications.splice(idx, 1);

            this._styleRecentNotifications();

            if (animated) {
                notification.getElement().velocity({
                    translateZ: 0,
                    // Force HA by animating a 3D property
                    translateY: ["130%", "0%"] // 130% to go behind tab-bar

                }, {
                    duration: ANIMATION_DURATION,
                    complete: onComplete
                });
            } else {
                onComplete();
            }
        } else {
            console.warn("NotificationManager: want to remove notification, but it's not in the stack");
        }
    };

    NotificationManager.prototype.removeAllNotifications = function removeAllNotifications() {
        while (this.notifications.length) {
            this.notifications[0].remove();
        }
    };

    NotificationManager.prototype._styleRecentNotifications = function _styleRecentNotifications() {
        var newNotification = this.notifications[this.notifications.length - 1],
            recentNotification = this.notifications[this.notifications.length - 2],
            lastNotification = this.notifications[this.notifications.length - 3];

        if (newNotification) {
            if (newNotification.getElement().hasClass("notification-stacked notification-darkened")) {
                newNotification.getElement().removeClass("notification-darkened");
                newNotification.getElement().velocity({
                    translateZ: 0,
                    // Force HA by animating a 3D property
                    translateX: ["0px", "-4px"],
                    translateY: ["0px", "4px"]
                }, {
                    duration: ANIMATION_DURATION,
                    complete: function () {
                        newNotification.getElement().removeClass("notification-stacked");
                    }
                });
            }
        }

        if (recentNotification) {
            recentNotification.getElement().removeClass("notification-hidden");

            if (!recentNotification.getElement().hasClass("notification-stacked")) {
                recentNotification.getElement().addClass("notification-stacked notification-darkened");
                recentNotification.getElement().velocity({
                    translateZ: 0,
                    // Force HA by animating a 3D property
                    translateX: ["-4px", "0px"],
                    translateY: ["4px", "0px"]
                }, {
                    duration: ANIMATION_DURATION,
                    complete: function () {
                    }
                });
            }
        }

        if (lastNotification) {
            lastNotification.getElement().addClass("notification-hidden");
        }
    };

    NotificationManager.prototype._addNotificationToArray = function _addNotificationToArray(notification) {
        /*
         positioning:
         ERROR
         INFO
         */
        var idx = this.notifications.length;

        if (notification.type === NotificationType.ERROR) {
            this.notifications.push(notification);
            return idx;
        } else {
            while (idx > 0 && this.notifications[idx - 1].type === NotificationType.ERROR) {
                idx--;
            }

            this.notifications.splice(idx, 0, notification);
            return idx;
        }
    };

    GUI.NotificationManager = NotificationManager;
    return GUI;
})();
