'use strict';
/*
 GeneralNotification:
 --------------------
 clickable
 closeable
 removeAfter
 */

(function () {
    class Notification extends GUI.View {
        constructor() {
            super($('<notification />'));
        }

        setConfig(config) {
            this.config = config; // auto-remove

            if (config.removeAfter > 0) {
                clearTimeout(this._closeTimer);
                this._closeTimer = setTimeout(this.remove.bind(this, true), config.removeAfter * 1000);
            }
        }

        viewDidAppear() {
            super.viewDidAppear(...arguments);
            this.eventHandlers = [];
            var swipeOptions = {
                swipeVelocityX: 0.1
            };
            this.eventHandlers.push(Hammer(this.element[0]).on('dragleft dragright', this._onDrag.bind(this)));
            this.eventHandlers.push(Hammer(this.element[0], swipeOptions).on('swipeleft', this._onSwipe.bind(this)));
            this.eventHandlers.push(Hammer(this.element[0], swipeOptions).on('swiperight', this._onSwipe.bind(this)));
            this.eventHandlers.push(Hammer(this.element[0]).on('dragend', this._onDragEnd.bind(this)));
        }

        viewDidDisappear() {
            while (this.eventHandlers.length) {
                this.eventHandlers.pop().dispose();
            }

            super.viewDidDisappear(...arguments);
        }

        destroy() {
            clearTimeout(this._closeTimer);
            this._closeTimer = null;
            this.config = null;
            super.destroy();
        }

        show() {
            NavigationComp.showNotification(this);
        }

        remove(animated) {
            NavigationComp.removeNotification(this, animated);
        }

        toggle() {
            if (typeof this._toggleState === "boolean") {
                this._toggleState = !this._toggleState; // invert
            } else {
                this._toggleState = true; // hide first time
            }

            NavigationComp.toggleNotification(this, this._toggleState);
        }

        _onDrag(e) {
            //console.log("_onDrag");
            // prevent iOS scrolling
            e.stopPropagation();
            e.preventDefault();
            e.gesture.stopPropagation();
            e.gesture.preventDefault();
            this._dragging = true;
            var xDelta = e.gesture.deltaX;
            this._dragPercent = xDelta / this.element.width();
            this.element.velocity({
                translateZ: 0,
                // Force HA by animating a 3D property
                translateX: xDelta + "px",
                opacity: 1 - Math.abs(this._dragPercent)
            }, {
                duration: 0
            });
        }

        _onSwipe(e) {
            //console.log("_onSwipe");
            // prevent iOS scrolling
            e.stopPropagation();
            e.preventDefault();
            e.gesture.stopPropagation();
            e.gesture.preventDefault();

            if (e.type === "swiperight") {
                this._swipeOut("right");
            } else {
                this._swipeOut("left");
            }
        }

        _onDragEnd(e) {
            //console.log("_onDragEnd");
            // prevent iOS scrolling
            e.stopPropagation();
            e.preventDefault();
            e.gesture.stopPropagation();
            e.gesture.preventDefault();

            if (this._dragging) {
                this._dragging = false;

                if (this._dragPercent < -0.45) {
                    this._swipeOut("left");
                } else if (this._dragPercent > 0.45) {
                    this._swipeOut("right");
                } else {
                    this._snapBack();
                }
            }
        }

        _swipeOut(direction) {
            this.element.velocity({
                translateZ: 0,
                // Force HA by animating a 3D property
                translateX: direction === "right" ? "100%" : "-100%",
                opacity: 0
            }, {
                duration: 200,
                complete: function () {
                    this.remove();
                }.bind(this)
            });
            this.emit(GUI.Notification.MARK_AS_READ_EVENT);
        }

        _snapBack() {
            this.element.velocity({
                translateZ: 0,
                // Force HA by animating a 3D property
                translateX: 0,
                opacity: 1
            }, {
                duration: 200
            });
        }

    }

    GUI.Notification = Notification;
    GUI.Notification.MARK_AS_READ_EVENT = "mark-as-read";
    GUI.Notification.CLICK_EVENT = "click";
})();
