'use strict';

/*
GeneralNotification:
--------------------
left icon
title
time
subtitle
close button
 */

class GeneralNotification extends GUI.Notification {
    //region Static
    static Template = function () {
        var getTemplate = function () {
            return $('<div class="general-notification__icon-placeholder" />' + '<div class="general-notification__texts">' + '   <div class="texts__top">' + '       <div class="top__title" />' + '       <div class="top__time" />' + '       <div class="top__flex" />' + '   </div>' + '   <div class="texts__subtitle" />' + '</div>' + '<div class="general-notification__close-icon-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/General/Notifications/button-cancel.svg", "close-icon-placeholder__icon") + '</div>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor() {
        super();
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.element.addClass("general-notification");
        this.element.append(GeneralNotification.Template.getTemplate());
        this.elements = {
            iconPlaceholder: this.element.find(".general-notification__icon-placeholder").hide(),
            titleLabel: this.element.find(".general-notification__texts .texts__top .top__title").hide(),
            timeLabel: this.element.find(".general-notification__texts .texts__top .top__time").hide(),
            subtitleLabel: this.element.find(".general-notification__texts .texts__subtitle").hide(),
            closeButton: this.element.find(".general-notification__close-icon-placeholder").toggle(!!this._closable)
        };
    }

    viewWillAppear() {
        super.viewWillAppear(...arguments);

        this._boundClose = function (ev) {
            if (this._closable) {
                this.remove(true);
                HapticFeedback(HapticFeedback.STYLE.SELECT);
            }

            ev.stopPropagation();
            ev.preventDefault(); //console.log(TitleBarCfg.Button.CLOSE);

            this.emit(GUI.Notification.MARK_AS_READ_EVENT);
        }.bind(this);

        this.elements.closeButton.on("click", this._boundClose);

        this._boundClick = function () {
            if (SandboxComponent.isEcoModeActive()) {
                SandboxComponent.toggleEcoModeShown(false);
            }
            this._clickable && this.emit(GUI.Notification.CLICK_EVENT); //console.log("element");
        }.bind(this);

        this.element.on("click", this._boundClick);
    }

    viewDidDisappear() {
        this.elements.closeButton.off("click", this._boundClose);
        this.element.off("click", this._boundClick);
        super.viewDidDisappear(...arguments);
    }

    setConfig(config) {
        this._clickable = !!config.clickable;
        this.setCloseable(!!config.closeable); //this._setHasIcon(!!config.hasIcon);

        super.setConfig(config);
    }

    setIcon(iconSrc) {
        if (typeof iconSrc === "string") {
            this.elements.iconPlaceholder.empty();

            if (iconSrc.indexOf("resources/") === 0 && iconSrc.indexOf(".svg") !== -1) {
                this.elements.iconPlaceholder.html(ImageBox.getResourceImageWithClasses(iconSrc, "icon-placeholder__icon"));
            } else {
                this.elements.iconPlaceholder.append($('<img src="' + iconSrc + '" class="icon-placeholder__icon">'));
            }

            this.elements.iconPlaceholder.show();
            this.elements.icon = this.elements.iconPlaceholder.find(".icon-placeholder__icon");
        } else {
            this.elements.iconPlaceholder.empty();
            this.elements.iconPlaceholder.hide();
            delete this.elements.icon;
        }
    }

    setIconColor(iconColor) {
        this.elements.icon && this.elements.icon.css("fill", iconColor);
    }

    setTitle(title) {
        this.elements.titleLabel.toggle(typeof title === "string" && title.length > 0);
        this.elements.titleLabel.text(title);
    }

    setSubtitle(subtitle) {
        this.elements.subtitleLabel.toggle(typeof subtitle === "string" && subtitle.length > 0);
        this.elements.subtitleLabel.text(subtitle);
    }

    setTime(ts) {
        this.elements.timeLabel.toggle(typeof ts === "number");
        this.elements.timeLabel.text(prettyPrintTime(ts)); // update every whole day the time (today, yesterday)

        this._timeUpdater && this._timeUpdater(); // cancel prev. timers

        this._timeUpdater = scheduleImpuls("day", function () {
            this.elements.timeLabel.text(prettyPrintTime(ts));
        }.bind(this));
    }

    setCloseable(closeable) {
        this.elements && this.elements.closeButton && this.elements.closeButton.toggle(closeable);
        this._closable = closeable;
    }

    destroy() {
        this.control = null;
        this._timeUpdater && this._timeUpdater();
        delete this._timeUpdater;
        super.destroy();
    }

}

GUI.GeneralNotification = GeneralNotification; // Static methods

GUI.Notification.createGeneralNotification = function createGeneralNotification(config, type) {
    var n = new GUI.GeneralNotification();
    n.setConfig({
        clickable: config.hasOwnProperty("clickable") ? config.clickable : false,
        closeable: config.hasOwnProperty("closeable") ? config.closeable : false,
        removeAfter: config.hasOwnProperty("removeAfter") ? config.removeAfter : 0
    });
    NavigationComp.showNotification(n, type); // set after the notification is show (because of viewDidLoad etc)

    typeof config.iconSrc !== "undefined" && n.setIcon(config.iconSrc);
    typeof config.iconColor !== "undefined" && n.setIconColor(config.iconColor);
    typeof config.title !== "undefined" && n.setTitle(config.title);
    typeof config.subtitle !== "undefined" && n.setSubtitle(config.subtitle);
    typeof config.time !== "undefined" && n.setTime(config.time);
    return n;
};
