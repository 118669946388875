'use strict';

window.GUI = function (GUI) {
    class AddMiniserverPopup extends GUI.GeneralPopup {
        constructor(type, content) {
            super(...arguments);
            content.title = _("miniserverlist.add");
            content.icon = Icon.ADD;
            content.color = window.Styles.colors.green; // add buttons

            if (PlatformComponent.getNetworkStatus().status === NetworkStatus.LAN && MiniserverFinder.hasMSFinder()) {
                content.buttons.push({
                    title: _("miniserverlist.add.search"),
                    type: GUI.PopupBase.ButtonType.CUSTOM,
                    color: content.color
                });
            }

            content.buttons.push({
                title: _("miniserverlist.add.manual"),
                type: GUI.PopupBase.ButtonType.CUSTOM,
                color: content.color
            });
            content.buttons.push({
                title: _("miniserverlist.add.test"),
                type: GUI.PopupBase.ButtonType.CUSTOM,
                color: content.color
            }); // cancel button

            content.buttons.push({
                title: _("cancel"),
                type: GUI.PopupBase.ButtonType.CANCEL,
                color: Color.STATE_INACTIVE
            });
            this.element.addClass("add-miniserver-popup");
        }

    }

    GUI.AddMiniserverPopup = AddMiniserverPopup;
    return GUI;
}(window.GUI || {});
