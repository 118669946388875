'use strict';

window.GUI = function (GUI) {
    class PopupBase extends GUI.View {
        //region Static
        static Template = function () {
            var getTemplate = function getTemplate(title, message) {
                var titleElem = "";

                if (title) {
                    titleElem = '<div class="popup__title">' + title + '</div>';
                }

                if (!message) {
                    message = "";
                }

                return $(titleElem + '<div class="popup__message">' + message + '</div>' + '<div class="popup__content"></div>' + '<form class="popup__form" name="form" novalidate autocomplete="off">' + '<div class="form__buttons-container"></div>' + '</form>');
            };

            var getButton = function getButton(title, index) {
                var identifier = "button-" + index; // beware: type submit causes a popstate if 2 popups are shown one after another!

                return $('<button id="' + identifier + '" class="buttons__button text-overflow-ellipsis clickable" type="button">' + title + '</button>');
            };

            return {
                getTemplate: getTemplate,
                getButton: getButton
            };
        }(); //endregion Static

        constructor(type, content) {
            super($('<div />'));
            this.type = type;
            this.content = content;
            this.elements = {};
            this.defer = Q.defer();
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.element.append(PopupBase.Template.getTemplate(this.content.title, this.content.message));
                this.elements.title = this.element.find('.popup__title');
                this.elements.message = this.element.find('.popup__message');
                this.elements.content = this.element.find('.popup__content');
                this.elements.form = this.element.find('.popup__form');
                this.elements.buttonContainer = this.element.find('.form__buttons-container');
                this.elements.buttons = [];
                return Q.all(this.content.buttons.map((btnCfg, i) => {
                    var buttonElm = PopupBase.Template.getButton(btnCfg.title, i),
                        button = new GUI.LxButton(this, buttonElm);
                    this.elements.buttons.push(button);
                    buttonElm.css("background-color", btnCfg.color);

                    if (btnCfg.type === GUI.PopupBase.ButtonType.OK) {
                        buttonElm.addClass("buttons__ok-button");
                    } else if (btnCfg.type === GUI.PopupBase.ButtonType.CANCEL) {
                        buttonElm.addClass("buttons__cancel-button");
                    }

                return this.appendSubview(button, this.elements.buttonContainer);
                })).then(() => {
                    if (this.content.messageColor) {
                        this.setMessageColor(this.content.messageColor);
                    }

                    this.elements.form.on("submit", function () {
                        return false;
                    });
                    this._boundCancelPopup = this._cancel.bind(this);
                });
            });
        }

        viewDidAppear() {
            let prms = super.viewDidAppear(...arguments);
            var btnElm, type;
            this.elements.buttons.forEach(function (button, i) {
                btnElm = button.getElement();

                if (btnElm.hasClass("buttons__ok-button")) {
                    type = GUI.PopupBase.ButtonType.OK;
                } else if (btnElm.hasClass("buttons__cancel-button")) {
                    type = GUI.PopupBase.ButtonType.CANCEL;
                } else {
                    type = GUI.PopupBase.ButtonType.CUSTOM;
                }

                button.onButtonTapped = this.buttonTapped.bind(this, type, i);
            }.bind(this));

            if (this.content.timeout) {
                this._startOkayTimeout();
            }

            NavigationComp.registerForBackNavigation(this._boundCancelPopup);
            return prms;
        }

        viewWillDisappear() {
            NavigationComp.unregisterFromBackNavigation(this._boundCancelPopup);

            this._clearOkayTimeout();

            return super.viewWillDisappear(...arguments);
        }

        // Public methods
        getPromise() {
            return this.defer.promise;
        }

        buttonTapped(type, index) {
            this._clearOkayTimeout();

            if (type === GUI.PopupBase.ButtonType.OK) {
                this.defer.resolve(GUI.PopupBase.ButtonType.OK);
            } else if (type === GUI.PopupBase.ButtonType.CANCEL) {
                this._cancel();
            } else {
                this.defer.resolve(index);
            }
        }

        /**
         * updates the title
         * @param title
         */
        setTitle(title) {
            this.elements.title.text(title);
        }

        /**
         * updates the message
         * @param message
         */
        setMessage(message) {
            this.elements.message.text(message);
        }

        setMessageColor(messageColor) {
            this.elements.message.css("color", messageColor);
        }

        _cancel() {
            this._clearOkayTimeout();

            this.defer.reject(GUI.PopupBase.ButtonType.CANCEL);
        }

        _okay() {
            this._clearOkayTimeout();

            this.defer.resolve(GUI.PopupBase.ButtonType.OK);
        }

        _startOkayTimeout() {
            this.okayTimeout = setTimeout(this._okay.bind(this), this.content.timeout);
        }

        _clearOkayTimeout() {
            this.okayTimeout && clearTimeout(this.okayTimeout);
            this.okayTimeout = null;
        }

    }

    GUI.PopupBase = PopupBase;
    return GUI;
}(window.GUI || {});

GUI.PopupBase.ButtonType = {
    OK: "ok",
    CANCEL: "cancel",
    CUSTOM: "custom"
};
