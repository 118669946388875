'use strict'; // TODO-thallth create a baseClass for InstructionBar + InstructionNotification!

class InstructionNotification extends GUI.View {
    //region Static
    static Template = function () {
        var getTemplate = function getTemplate() {
            return $('<instruction-notification>' + '   <div class="instruction-notification__text"></div>' + '   <div class="instruction-notification__buttons"></div>' + '   <div class="instruction-notification__minimize-btn">' + '       <div class="minimize-btn__touch-area">' + '           <div class="touch-area__icon-placeholder">' + ImageBox.getResourceImageWithClasses("resources/Images/General/arrow-back.svg", "icon-placeholder__icon") + '           </div>' + '       </div>' + '   </div>' + '</instruction-notification>');
        };

        return {
            getTemplate: getTemplate
        };
    }(); //endregion Static

    constructor(type) {
        super(InstructionNotification.Template.getTemplate());
        this.type = type;
        this._minimized = false;
    }

    viewDidLoad() {
        // hide normal notifications when displaying an instructionNotification
        return super.viewDidLoad(...arguments).then(function () {
            NavigationComp.toggleNotifications(false);
            this.elements = {
                textLbl: this.element.find(".instruction-notification__text"),
                buttonCancel: this._getButton("resources/Images/ActiveMiniserver/NFC/button-cancel.svg", "button__icon cancel-icon"),
                minimizeBtn: this.element.find(".instruction-notification__minimize-btn"),
                minimizeBtnIcon: this.element.find(".instruction-notification__minimize-btn .touch-area__icon-placeholder"),
                buttonsPlaceholder: this.element.find(".instruction-notification__buttons")
            };
            this.elements.minimizeBtn.on("click", function () {
                if (this._minimized) {
                    this._animateIn();
                } else {
                    this._animateOut();
                }
            }.bind(this));

            this._addButton(this.elements.buttonCancel);
        }.bind(this))
    }

    viewWillAppear() {
        var vwAPrms = super.viewWillAppear(...arguments);

        this.element.velocity({
            translateX: "-100%",
            left: "20px"
        }, {
            duration: 0
        });
        this.elements.minimizeBtnIcon.velocity({
            rotateZ: "180deg"
        }, {
            duration: 0
        });

        return vwAPrms;
    }

    viewDidAppear() {
        var vdAPrms = super.viewDidAppear(...arguments);

        this._animateIn();

        this._boundCancel = this._onCancel.bind(this);
        this.elements.buttonCancel.on("click", this._boundCancel);

        return vdAPrms;
    }

    viewWillDisappear() {
        this.element.removeClass("animate-background");
        return super.viewWillDisappear(...arguments);
    }

    viewDidDisappear() {
        this.elements.buttonCancel.off("click", this._boundCancel);
        return super.viewDidDisappear(...arguments);
    }

    destroy() {
        // show normal notifications again
        NavigationComp.toggleNotifications(true);
        return super.destroy(...arguments);
    }

    _onCancel() {
        if (this.type === Interaction.NFC.WRITE_POSITION || this.type === Interaction.QR.WRITE_POSITION || this.type === Interaction.QuickAction.WRITE_POSITION) {
            NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Position);
        } else if (this.type === Interaction.NFC.WRITE_COMMAND || this.type === Interaction.QR.WRITE_COMMAND || this.type === Interaction.QuickAction.WRITE_COMMAND) {
            NavigationComp.stopActivity(GUI.ActiveMiniserverViewController.Activity.Command);
        }
    }

    setText(text) {
        this.elements.textLbl.text(text);
    }

    _getButton(iconSrc, classes) {
        return $('<div class="buttons__button">' + ImageBox.getResourceImageWithClasses(iconSrc, classes) + '</div>');
    }

    _addButton(button) {
        this.elements.buttonsPlaceholder.append(button);
    }

    _animateOut() {
        this._minimized = true;
        this.element.removeClass("animate-background");
        this.element.velocity({
            translateZ: 0,
            // Force HA by animating a 3D property
            translateX: "-100%",
            left: "20px"
        }, {
            duration: 400
        });
        this.elements.minimizeBtnIcon.velocity({
            translateZ: 0,
            // Force HA by animating a 3D property
            rotateZ: "180deg"
        }, {
            duration: 400
        });
    }

    _animateIn() {
        this._minimized = false;
        this.element.velocity({
            translateZ: 0,
            // Force HA by animating a 3D property
            translateX: 0,
            left: 0
        }, {
            duration: 400
        });
        this.elements.minimizeBtnIcon.velocity({
            translateZ: 0,
            // Force HA by animating a 3D property
            rotateZ: "0deg"
        }, {
            duration: 400,
            complete: function () {
                this.element.addClass("animate-background");
            }.bind(this)
        });
    }

}

GUI.InstructionNotification = InstructionNotification;
