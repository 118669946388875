'use strict';

window.GUI = function (GUI) {
    class RatingPopup extends GUI.GeneralPopup {
        //region Static
        static Template = function () {
            var getRatingStars = function getRatingStars(starsCnt) {
                var elemString = '<div class="content__stars-placeholder">';

                for (var i = 0; i < starsCnt; i++) {
                    elemString += ImageBox.getResourceImageWithClasses(Icon.STAR, "stars-placeholder__star");
                }

                elemString += '</div>';
                return $(elemString);
            };

            return {
                getRatingStars: getRatingStars
            };
        }(); //endregion Static

        constructor(type, content) {
            super(...arguments);
            this.element.addClass("rating-popup");
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                this.elements.content.append(RatingPopup.Template.getRatingStars(this.content.availableStars));
                this.elements.stars = this.element.find('.stars-placeholder__star');
                this.buttons = this.elements.stars.map(function (elmIdx) {
                    var btn = new GUI.LxButton(this, this.elements.stars[elmIdx]);
                    this.addToHandledSubviews(btn);
                    btn.onButtonTapped = this._onRatingChanged.bind(this, elmIdx + 1);
                    return btn;
                }.bind(this));
                this.content.rating = this.content.rating || 0;
                this._setRating(this.content.rating);
            });
        }

        _onRatingChanged(rating) {
            this._setRating(rating);

            this.buttonTapped(GUI.PopupBase.ButtonType.CUSTOM, rating);
        }

        _setRating(rating) {
            if (rating) {
                this.elements.stars.slice(0, rating).css("fill", window.Styles.colors.stateActive);
            }
        }

    }

    GUI.RatingPopup = RatingPopup;
    return GUI;
}(window.GUI || {});
