'use strict';
/**
 * [maxDuration]             Maximum length of the recording  --> Default 60sec
 * [countDownInSeconds]      countDown timer                  --> Default 3sec
 */

window.GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var maxDuration = 60,
            countDownTimer = 3;

        class AudioRecordPopup extends GUI.GeneralPopup {
            //region Static
            static Template = function () {
                var getTemplate = function getTemplate(content) {
                    var title = '',
                        subTitle = '';

                    if (content.title) {
                        title = '<div class="text-container__title">' + content.title + '</div>';
                    }

                    if (content.subTitle) {
                        subTitle = '<div class="text-container__sub-title">' + content.subTitle + '</div>';
                    }

                    return $('<div class="pre-popup-header__text-container">' + title + subTitle + '</div>');
                };

                return {
                    getTemplate: getTemplate
                };
            }(); //endregion Static

            constructor(type, content) {
                super(...arguments);
                this.element.addClass("audio-record-popup");
                this.countDownInSeconds = content.hasOwnProperty('countDownInSeconds') ? content.countDownInSeconds : countDownTimer;
                this.maxDuration = content.hasOwnProperty('maxDuration') ? content.maxDuration : maxDuration;
                this.audioResult = {};
            }

            viewDidLoad() {
                super.viewDidLoad(...arguments);
                this.element.prepend(AudioRecordPopup.Template.getTemplate(this.content));
                this.elements.title.remove();
                this.elements.title = this.element.find('.custom__title');
                this.elements.subTitle = this.element.find('.custom__sub-title');
                this.elements.buttons[0].setEnabled(false);
            }

            viewDidAppear() {
                super.viewDidAppear(...arguments);

                this._startCountdown();
            }

            viewWillDisappear() {
                this._stopRecordingAndIntervals();

                super.viewWillDisappear(...arguments);
            }

            buttonTapped(type, index) {
                if (index === 0) {
                    this.defer.resolve(this.audioResult);
                } else {
                    super.buttonTapped(type, index);
                }
            }

            // -----------
            // Private
            // -----------
            _startCountdown() {
                var startTimestamp = this.countDownInSeconds;
                this.setMessage(_('media.announcement.recording-countdown', {
                    countdown: LxDate.formatSecondsIntoDigits(startTimestamp, false, true)
                }));
                this.countDownInterval = setInterval(function () {
                    --startTimestamp;
                    this.setMessage(_('media.announcement.recording-countdown', {
                        countdown: LxDate.formatSecondsIntoDigits(startTimestamp, false, true)
                    }));

                    if (startTimestamp === 0) {
                        clearInterval(this.countDownInterval);

                        this._startRecordingCountDown();
                    }
                }.bind(this), TimerInterval);
            }

            _startRecordingCountDown() {
                var startTimestamp = this.maxDuration;

                this._startRecording(); // set up recording UI


                this.elements.buttons[0].setEnabled(true);
                this.setMessage(LxDate.formatSecondsIntoDigits(startTimestamp, false, true));

                this._setButtonColor(Color.RED);

                this._setIconRecording(true);

                this.timerTimerInterval = setInterval(function () {
                    --startTimestamp;
                    this.setMessage(LxDate.formatSecondsIntoDigits(startTimestamp, false, true));

                    if (startTimestamp === 0) {
                        this._setIconRecording(false);

                        this._stopRecordingAndIntervals();
                    }
                }.bind(this), TimerInterval);
            }

            _startRecording() {
                var prefix = "";

                if (device.platform === PlatformType.IOS) {
                    this.filename = 'audio_recording.wav';
                    this.pathWithoutName = cordova.file.tempDirectory;
                    prefix = 'cdv';
                } else if (device.platform === PlatformType.Android) {
                    this.filename = 'audio_recording.mp3';
                    this.pathWithoutName = cordova.file.externalDataDirectory;
                }

                this.path = this.pathWithoutName + this.filename;
                this.audioResult.path = this.path;
                this.audioResult.pathWithoutName = this.pathWithoutName;
                this.audioResult.filename = this.filename;
                this.mediaObject = new Media(prefix + this.path);
                this.mediaObject.startRecord();
            }

            _stopRecordingAndIntervals(removePopup) {
                this.timerTimerInterval && clearInterval(this.timerTimerInterval);
                this.countDownInterval && clearInterval(this.countDownInterval);
                this.mediaObject && this.mediaObject.stopRecord();
            }

            _setIconRecording(active) {
                this.element.toggleClass("audio-record-popup--recording", active);
            }

            _setButtonColor(color) {
                this.element.find('#button-0').css("background-color", color);
            }

        }

        GUI.AudioRecordPopup = AudioRecordPopup;
    }
    return GUI;
}(window.GUI || {});
