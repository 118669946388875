'use strict';

window.GUI = function (GUI) {
    class GeneralPopup extends GUI.PopupBase {
        //region Static
        static Template = function () {
            var getTemplate = function getBaseTemplate(iconSrc) {
                var iconElem = '';

                if (iconSrc) {
                    if (iconSrc.indexOf(".svg") !== -1) {
                        iconElem = ImageBox.getResourceImageWithClasses(iconSrc, "header__icon");
                    } else {
                        iconElem = '<img class="header__icon" src="' + iconSrc + '">';
                    }
                }

                return $('<div class="popup__header">' + iconElem + '</div>');
            };

            return {
                getTemplate: getTemplate
            };
        }(); //endregion Static

        constructor(type, content) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.element.prepend(GeneralPopup.Template.getTemplate(this.content.icon));
                this.elements.header = this.element.find('.popup__header');
                this.elements.header.toggleClass("header--with-icon", !!this.content.icon); // color

                this.elements.header.find(".header__icon").css({
                    fill: this.content.color || Color.ICON_A
                });
            });
        }
    }

    GUI.GeneralPopup = GeneralPopup;
    return GUI;
}(window.GUI || {});
