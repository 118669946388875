'use strict';

window.GUI = function (GUI) {
    class NativePopup extends GUI.PopupBase {
        constructor(type, content) {
            super(...arguments); // Just assign the OS popups defer to the the NativePopups defer

            this.defer = content.defer;
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(() => {
                // Remove every UI element, so we just have the darkner,
                // the UI is provided by the OS.
                delete this.elements.title;
                delete this.elements.message;
                delete this.elements.content;
                delete this.elements.form;
                delete this.elements.buttonContainer;
                this.element.empty();
            });
        }

    }

    /**
     * The NativePopup is a popup without any UI, it can be used to block the PopupQue if a Native OS popup is displayed.
     * Eg. The BiometricHelper displays the native OS dependent biometric authentication dialog. Out PopupManager needs to know
     * when such a dialog is been displayed to not show our popups underneath the native os dialog.
     *
     * Just pass the native defer as parameter defer in the config. This will fix the navigation queue.
     */
    GUI.NativePopup = NativePopup;
    return GUI;
}(window.GUI || {});
